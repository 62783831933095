var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    section: true,
    'section-vision': true,
    [`section${_vm.orderNo}`]: true
  },style:({
    'background-image': `url(${_vm.resourceUrl(_vm.data.backgroundImage)})`,
  })},[_c('a',{staticClass:"navigation-target",attrs:{"id":_vm.hash,"href":"javascript:void(0);"}}),_vm._v(" "),(_vm.data.backgroundImage)?_c('div',{staticClass:"background-opacity"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"top-title"},[_c('AtomsCommonAtomTitle',{attrs:{"color":"#fff","color2":"#fff","title":_vm.titleString,"subtitle":_vm.subtitleString}})],1),_vm._v(" "),(!_vm.data.noAnimation)?_c('div',{staticClass:"effect-building"},[_c('img',{attrs:{"src":_vm.buildingSvg,"alt":"SVG Animate"}}),_vm._v(" "),_c('div',{staticClass:"black-background"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row bottom-elements align-items-center"},[_c('div',{staticClass:"col col-xs-12 col-6"},[_c('OrganismsCommonOrganismTitleAndDescription',{attrs:{"vision-data":_vm.data.vision}})],1),_vm._v(" "),_c('div',{staticClass:"col col-xs-12 col-6"},[_c('ClientOnly',[_c('LazyOrganismsCommonOrganismContentSlider',{attrs:{"slider-content-data":_vm.data.slider}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }